import router from './router'
import i18n from '@/locale'

export default {
    // ! общедоступные данные
    data: {
        apiEndpoint:
            process.env.NODE_ENV === 'development'
                ? 'http://127.0.0.1:8880/api'
                : '/api',

        // ! первый месяц с существующим отчетом
        // необходимо для заполнения списка с отчетами на фронте
        firstReportMonth: '2022-02',
        domain: 'whatsfy.com',

        tariffs: [
            {
                months: 3,
                price: 19.99,
                ru_price: 999,
                features: [
                    '✅ ' + i18n.t('PyzKrOgVWyirA7VpxcOe_'),
                    '✅ ' + i18n.t('BtWHRRRAOUOFWfMt_IX2P')
                ]
            },
            {
                months: 6,
                price: 35.99,
                ru_price: 1749,
                features: [
                    '✅ ' + i18n.t('9KmH1etN9pjPG6D0vAn2h'),
                    '✅ ' + i18n.t('BtWHRRRAOUOFWfMt_IX2P')
                ]
            },
            {
                months: 1,
                price: 16.99,
                ru_price: 849,
                features: [
                    '✅ ' + i18n.t('5KWRflePhcXbxFtzzvLIl'),
                    '✅ ' + i18n.t('3StMl7z-Nd_vhAVb8tYXx'),
                    '❌ ' + i18n.t('HzMckpOAfl40Elba0n2Bv')
                ]
            },
            {
                months: 0,
                price: 0,
                features: [
                    '✅ ' + i18n.t('XJz5Ll5DB7MTtrqWFULLt'),
                    '❌ ' + i18n.t('alkAAe88Tf51CakDN4ISd')
                ]
            }
        ],

        recaptchaSitekey: '6Lf97EcdAAAAAIDLlJfWgVJD3KwO_e_tvBvGLadc',

        langs: [
            { local: 'en', language: 'English' },
            { local: 'ru', language: 'Русский' }
            // { local: 'de', language: 'German' },
            // { local: 'es', language: 'Spanish' }
            // { local: 'fr', language: 'French' },
            // { local: 'tr', language: '🇹🇷 Turkish' },
            // { local: 'pt', language: '🇧🇷 Português' },
            // { local: 'it', language: '🇮🇹 Italian' },
            // { local: 'pl', language: '🇵🇱 Polish' },
            // { local: 'nl', language: '🇳🇱 Netherlands' }
        ],

        sw_countries: require('@/data/sw_countries.json'), // данные по странам в формате similarweb
        flags: require('@/data/flag-emojis-by-code.json'), // https://github.com/risan/country-flag-emoji-json
        languages: require('@/data/languages.json') // https://github.com/annexare/Countries/blob/master/data/languages.json
    },

    // ! настройки GTM
    gtm: {
        id: 'GTM-MSRXKRR',
        enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
        debug: false, // Whether or not display console logs debugs (optional)
        loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
        vueRouter: router // Pass the router instance to automatically sync with router (optional)
    }
}
