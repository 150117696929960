import i18n from '@/locale'
import Vue from 'vue'
import router from '@/router'
import config from '@/config'
import { getCookie, setCookie, deleteCookie } from '@/plugins/cookies'

export default {
    state: {
        id: null,
        email: null,
        token: null,
        cacheId: null, // кеш айди нужен для сброса кеша на сервере, если что-то изменилось
        reports: [] // список отчеток, к которым юзер имеет доступ
    },

    mutations: {
        SET_USER (state, payload) {
            // если payload пустой, то обнуляем стейт
            Object.assign(
                state,
                payload || {
                    id: null,
                    email: null,
                    token: null,
                    reports: []
                },
                { cacheId: Math.random() }
            )
        },
        SET_USER_REPORTS (state, payload) {
            state.reports = payload
        }
    },

    actions: {
        async applyPromocode ({ state, commit, dispatch }, payload) {
            commit('SET_LOADING', true)
            commit('CLEAR_MESSAGE')

            const auth = state.token || getCookie('authtoken')

            try {
                const res = await fetch(
                    `${config.data.apiEndpoint}/promocode`,
                    {
                        method: 'POST',
                        body: JSON.stringify(payload),
                        headers: {
                            Authorization: `Bearer: ${auth}`,
                            'Content-Type': 'application/json;charset=utf-8'
                        }
                    }
                )
                const response = await res.json()
                if (response.error) throw new Error(response.message)

                await dispatch('autoLogin')

                commit('CLEAR_MESSAGE')
                commit('SET_MESSAGE', {
                    type: 'success',
                    text: i18n.t('Rf3RCZBcfv6BZ11BYvcIr')
                })
                commit('SET_LOADING', false)
            } catch (error) {
                commit('SET_MESSAGE', { type: 'error', text: error.message })
                commit('SET_LOADING', false)
                throw error
            }
        },

        async autoLogin ({ state, commit }) {
            /** Автологин на сайт
             */

            const auth = state.token || getCookie('authtoken')
            if (!auth) return

            commit('CLEAR_MESSAGE')
            commit('SET_LOADING', true)

            try {
                const response = await fetch(
                    `${config.data.apiEndpoint}/userinfo`,
                    {
                        headers: {
                            Authorization: `Bearer: ${auth}`
                        }
                    }
                )
                const user = await response.json()

                if ('error' in user) throw new Error(user.message)

                commit('SET_USER', user)
                commit('SET_LOADING', false)
            } catch (error) {
                commit('SET_USER', null)
                deleteCookie('authtoken')
                commit('SET_LOADING', false)
                commit('SET_MESSAGE', { type: 'error', text: error.message })
            }
        },

        async logoutUser ({ commit }) {
            /** Выход юзера
             */
            commit('SET_USER', null)
            deleteCookie('authtoken')
            router.push({ name: 'login' })
        },

        async regUser ({ commit }, payload) {
            /** Регистрация нового юзера
             */
            commit('CLEAR_MESSAGE')
            commit('SET_LOADING', true)

            try {
                const response = await fetch(
                    `${config.data.apiEndpoint}/register`,
                    {
                        method: 'POST',
                        body: JSON.stringify(payload),
                        headers: {
                            'Content-Type': 'application/json;charset=utf-8'
                        }
                    }
                )
                const reg = await response.json()

                if ('error' in reg) throw new Error(reg.message)

                Vue.gtm.trackEvent({ event: 'USER_REG' })

                setCookie('authtoken', reg.token, {
                    'max-age': 60 * 60 * 24 * 30
                })

                commit('SET_USER', { ...reg })

                commit('SET_LOADING', false)
                commit('SET_MESSAGE', {
                    type: 'success',
                    text: i18n.t('xMZUkWxObzXoHCujLWTCw')
                })

                return true
            } catch (error) {
                commit('SET_LOADING', false)
                commit('SET_MESSAGE', { type: 'error', text: error.message })
                return false
            }
        },

        async loginUser ({ commit }, payload) {
            /** Логин пользователя
             */
            commit('CLEAR_MESSAGE')
            commit('SET_LOADING', true)
            try {
                const response = await fetch(
                    `${config.data.apiEndpoint}/login`,
                    {
                        method: 'POST',
                        body: JSON.stringify(payload),
                        headers: {
                            'Content-Type': 'application/json;charset=utf-8'
                        }
                    }
                )
                const user = await response.json()

                if ('error' in user) throw new Error(user.message)

                setCookie('authtoken', user.token, {
                    'max-age': 60 * 60 * 24 * 30
                })

                commit('SET_USER', { ...user })

                commit('SET_LOADING', false)
                commit('SET_MESSAGE', {
                    type: 'success',
                    text: i18n.t('UVMOkFscGxeVsmLUReCBB')
                })
                Vue.gtm.trackEvent({ event: 'USER_LOGIN' })

                return true
            } catch (error) {
                commit('SET_LOADING', false)
                commit('SET_MESSAGE', { type: 'error', text: error.message })
                return false
            }
        },

        getUserId ({ state }) {
            /** Возвращает ID юзера
             */
            return state.id
        },
        getCacheId ({ state }) {
            /** Возвращает ID юзера
             */
            return state.cacheId
        }
    },

    getters: {
        /** Возвращает весь стейт юзера
         */
        user (state) {
            return state
        },
        /** Проверяет, залогинен ли юзер
         */
        isLogin (state) {
            return state.id !== null
        },
        /** Возвращает баланс юзера
         */
        userReports (state) {
            return state.reports
        }
    }
}
