import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

// https://lobotuerto.com/vuetify-color-theme-builder/
const vuetifyOpts = {
    rtl: false,
    theme: {
        dark: false,
        themes: {
            dark: {
                primary: '#6689C1',
                accent: '#FF4081',
                secondary: '#A2D8E8',
                success: '#4CAF50',
                info: '#2196F3',
                warning: '#FB8C00',
                error: '#FF5252'
            },
            light: {
                primary: '#6689C1',
                accent: '#e91e63',
                secondary: '#A2D8E8',
                success: '#4CAF50',
                info: '#2196F3',
                warning: '#FB8C00',
                error: '#FF5252'
            }
        }
    }
}

vuetifyOpts['theme']['themes']['dark']['panel'] = '#223146'
vuetifyOpts['theme']['themes']['light']['panel'] = '#223146'

export default new Vuetify(vuetifyOpts)

// export default new Vuetify({
//     theme: {
//         dark: true,
//         themes: {
//             dark: {
//                 primary: '#535FD7',
//                 secondary: '#7C85E1',
//                 accent: '#93F4FE',
//                 error: '#b71c1c',
//                 panel: '#223146'
//             }
//         }
//     }
// })
