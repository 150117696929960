import Vue from 'vue'
import Vuex from 'vuex'

import commons from './commons'
import report from './report'
import user from './user'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        commons,
        report,
        user
    }
})
