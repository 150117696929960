<template>
  <v-app>

    <template v-if="$root.country === 'RU' || $i18n.locale === 'ru'">
      <!-- Yandex.RTB R-A-1305157-1 -->
      <script2>window.yaContextCb.push(()=>{
        Ya.Context.AdvManager.render({
        type: 'fullscreen',
        platform: 'touch',
        blockId: 'R-A-1305157-1'
        })
        })</script2>

      <!-- Yandex.RTB R-A-1305157-2 -->
      <script2>window.yaContextCb.push(()=>{
        Ya.Context.AdvManager.render({
        type: 'floorAd',
        blockId: 'R-A-1305157-2'
        })
        })</script2>
    </template>

    <v-navigation-drawer
      v-model="drawer"
      color="panel"
      app
      dark
    >

      <v-list
        dense
        nav
        subheader
      >

        <v-list-item
          dense
          class="mt-5"
        >
          <v-list-item-icon>
            <v-icon color="grey">mdi-account-circle-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-truncate grey--text">{{user.email}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider class="my-5"></v-divider>

        <v-subheader v-if="$route.name === 'report' && report">{{ $t('oUHwOzrsSf_SLMC8-UmKI') }}</v-subheader>

        <v-list-item
          dense
          v-show="$route.name === 'report' && report && item.enable"
          v-for="(item, index) in reportTOC"
          :key="'TOC2'+index"
          link
          @click="scrollTo(item.scroll)"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider
          class="my-5"
          v-if="$route.name === 'report' && report"
        ></v-divider>

        <v-list-item
          v-for="item in items"
          :key="item.title"
          :to="item.url"
          link
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      color="panel"
      dark
      dense
    >
      <v-app-bar-nav-icon
        class="hidden-md-and-up"
        @click="drawer = !drawer"
      >
        <v-icon>mdi-menu</v-icon>
      </v-app-bar-nav-icon>

      <router-link
        to="/"
        tag="div"
        class="d-flex align-center pointer"
      >
        <v-img
          alt="Whatsfy Logo"
          class="shrink mr-2"
          contain
          src="/app/icons/android-chrome-192x192.png"
          transition="scale-transition"
          width="40"
        />
        <v-toolbar-title>
          <span class="font-weight-black">
            W H A T S F Y
          </span>
          <!-- <sup>β</sup> -->
        </v-toolbar-title>
      </router-link>

      <v-spacer></v-spacer>

      <v-toolbar-items class="hidden-sm-and-down">

        <v-menu
          offset-y
          dense
          v-if="$route.name === 'report' && report && !loading"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              v-bind="attrs"
              v-on="on"
            >
              <v-icon left>mdi-table-of-contents</v-icon>
              {{ $t('oUHwOzrsSf_SLMC8-UmKI') }}
            </v-btn>
          </template>

          <v-list dense>
            <v-list-item
              v-for="(item, index) in reportTOC"
              :key="'TOC'+index"
              @click="scrollTo(item.scroll)"
              v-show="item.enable"
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-btn
          text
          dark
          v-for="item in items"
          :key="item.title"
          :to="item.url"
        >
          <v-icon left>{{item.icon}}</v-icon>
          {{item.title}}
        </v-btn>

      </v-toolbar-items>

    </v-app-bar>

    <v-main>
      <router-view></router-view>

      <!-- <v-banner
        outlined
        single-line
        sticky
        tile
        class="sale-bnnr text-center caption  blue lighten-2 "
      >
        {{ $t('LNS9oV73SN5ltZ5c1vFL9') }} ✉️
        <a
          class="white--text"
          href="mailto:hi@whatsfy.com?subject=For%20sale"
        >hi@whatsfy.com</a>
      </v-banner> -->

    </v-main>

    <v-footer
      class="mt-16 pt-10"
      color="panel"
      dark
    >

      <v-card
        class="white--text"
        flat
        dark
        color="panel"
        tile
        width="100%"
      >
        <v-row>
          <v-spacer></v-spacer>
          <v-col
            cols="12"
            md="4"
            lg="3"
          >
            <h4 class="overline pb-3 grey--text">{{ $t('msIIoW7WWTN893iUl5_lR') }}</h4>
            <ul>
              <li
                v-for="item in items"
                :key="item.title"
                class="caption footer-list"
              >
                <v-icon
                  left
                  color="grey"
                  size="12"
                >{{item.icon}}</v-icon>
                <router-link
                  class="text-decoration-none"
                  :to="item.url"
                >{{item.title}}</router-link>
              </li>
            </ul>
          </v-col>

          <v-col
            cols="12"
            md="4"
            lg="3"
          >
            <h4 class="overline pb-3 grey--text">{{ $t('CpmWK41L6PDo4BSqvzGAO') }}</h4>
            <ul>
              <li
                v-for="(item,i) in socnets"
                :key="i"
                class="caption"
              >
                Growing sites in {{Object.keys(item)[0]}}:

                <span
                  v-for="n in item[Object.keys(item)[0]]"
                  :key="n.url"
                >
                  <JsUrl
                    class="text-decoration-none"
                    :url="n.url"
                  >
                    <v-icon
                      color="grey"
                      size="20"
                      class="mb-1 px-1"
                    >
                      {{n.icon}}
                    </v-icon>
                  </JsUrl>
                </span>

              </li>
            </ul>
          </v-col>

          <v-col
            cols="12"
            md="4"
            lg="3"
          >
            <!-- <div>
              <v-select
                class="pt-4"
                v-model="selectLang"
                outlined
                dense
                return-object
                :items="$root.langs"
                :label="$t('E6fRmd0q-ZKMI2VDTuMyj')"
                item-text="language"
                @change="langChanger(selectLang)"
              >
              </v-select>
            </div> -->
            <div>
              <v-switch
                prepend-icon="mdi-white-balance-sunny"
                inset
                v-model="$vuetify.theme.dark"
              ></v-switch>
            </div>
          </v-col>

          <v-spacer></v-spacer>

        </v-row>

        <v-row>
          <v-col>
            <v-card-text class="white--text text-center">
              <p>2021—{{ new Date().getFullYear() }} © <strong>Whatsfy.com</strong>, <a href="mailto:hi@whatsfy.com">hi@whatsfy.com</a>.
                <br />

                <a
                  target="_blank"
                  href="/legal/terms.html"
                >Terms of Service</a> |
                <a
                  target="_blank"
                  href="/legal/refund.html"
                >Refund Policy</a> |
                <a
                  target="_blank"
                  href="/legal/privacy.html"
                >Privacy Policy</a>

                <br />
                <img src="/assets/img/visa_mastercard.png">
              </p>
            </v-card-text>
          </v-col>
        </v-row>
      </v-card>

    </v-footer>

    <Notifications />

    <v-dialog
      v-model="logoutDialog"
      max-width="350"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ $t('eHMDhZyjWTrqPigS97he_') }}
        </v-card-title>

        <v-card-text>
          {{ $t('3MOa52e9vf8tv2BRAhwbi', {'u':user.email}) }}
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="primary"
            @click="logoutDialog = false"
          >
            {{ $t('MCJiwJsAQ4FAW0bVdt0X_') }}
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
            color="primary"
            text
            outlined
            @click="logout()"
          >
            {{ $t('jaExs2UU7WxuAeX_ASn8t') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-app>
</template>

<script>
import JsUrl from '@/components/JsUrl'
import Notifications from '@/components/Notifications'
import goTo from 'vuetify/es5/services/goto'
import { mapGetters } from 'vuex'

export default {
  name: 'App',

  components: {
    Notifications, JsUrl
  },

  data: () => ({
    selectLang: {},
    drawer: false,
    logoutDialog: false,
  }),

  mounted () {
    this.selectLang = this.$root.langs.find(c => {
      return c.local === navigator.language.slice(0, 2)
    }) || { local: 'en', language: 'English' }
  },

  methods: {
    langChanger (selectLang) {
      this.$store.dispatch('setLang', selectLang.local)
      this.$store.dispatch('setMessage', { text: this.$t('VYb2gLQinymbWh9vx4F7h') + ' ' + selectLang.language })
    },

    scrollTo (id) {
      goTo(id, { offset: 10 })
      this.drawer = false
    },

    logout () {
      this.logoutDialog = false
      this.$router.push({ name: 'logout' })
    }
  },

  computed: {
    ...mapGetters(['report', 'user', 'loading']),

    isLogin () {
      return this.$store.getters.isLogin
    },
    reportTOC () {
      return [

        { enable: this.report.similarweb && Object.keys(this.report.similarweb).length, title: this.$i18n.t('XJlWC_8rQAUTU6aUcktTJ'), icon: 'mdi-chart-areaspline', scroll: '#SimilarWeb' },

        { enable: this.report.majestic && Object.keys(this.report.majestic).length, title: this.$i18n.t('S7pPXNyLNbqxNJRHh_jo4'), icon: 'mdi-chart-box-outline', scroll: '#Majestic' },

        { enable: this.report.moz && Object.keys(this.report.moz).length, title: this.$i18n.t('PNQm4YKK_ilIA1ZaDThUB'), icon: 'mdi-chart-box-outline', scroll: '#MozCom' },

        { enable: this.report.google && Object.keys(this.report.google).length, title: this.$i18n.t('0WSnY47-VKLNQCNi-8NWh'), icon: 'mdi-google', scroll: '#Google' },

        { enable: this.report.mywot && Object.keys(this.report.mywot).length, title: this.$i18n.t('K_jMTt4qmAm3IrOUOL0-z'), icon: 'mdi-shield-check-outline', scroll: '#MyWot' },

        { enable: this.report.content_info && Object.keys(this.report.content_info).length, title: this.$i18n.t('SWmrQ4NlcZQOk6fl3kJoS'), icon: 'mdi-file-document-outline', scroll: '#Content' },

        { enable: this.report.web_archive && Object.keys(this.report.web_archive).length, title: this.$i18n.t('DFGwwC5keNzT-l7k6GHji'), icon: 'mdi-archive-clock-outline', scroll: '#WebArchive' },

        { enable: this.report.website_ids && Object.keys(this.report.website_ids).length, title: this.$i18n.t('XFp5XDX2vvzJ8210vJ5QV'), icon: 'mdi-fingerprint', scroll: '#WebsiteIds' },

        { enable: this.report.builtwith && Object.keys(this.report.builtwith).length, title: this.$i18n.t('9zqxaLlMF8QMcGv43II4b'), icon: 'mdi-wrench-outline', scroll: '#BuiltWith' },

        { enable: this.report.whois && Object.keys(this.report.whois).length, title: this.$i18n.t('4LKcBwYX0h5qXUCLo0KiH'), icon: 'mdi-information-outline', scroll: '#Whois' },

        { enable: true, title: this.$i18n.t('m57WN51PPUCGz5y6Cylox'), icon: 'mdi-bullseye-arrow', scroll: '#SEO' },

        { enable: this.report.similarsites && Object.keys(this.report.similarsites).length, title: this.$i18n.t('djPkqsWFeKF6gUPqfArUj'), icon: 'mdi-magnify', scroll: '#SimilarSites' },
      ]
    },
    socnets () {
      return [
        {
          'USA': [
            // { icon: 'mdi-twitter', url: 'https://twitter.com/Whatsfy_US' },
            { icon: 'mdi-send', url: 'https://t.me/whatsfy_us' },
          ]
        },
        {
          'Germany': [
            // { icon: 'mdi-twitter', url: 'https://twitter.com/Whatsfy_DE' },
            { icon: 'mdi-send', url: 'https://t.me/whatsfy_de' },
          ]
        },
        {
          'Spain': [
            { icon: 'mdi-send', url: 'https://t.me/whatsfy_es' },
          ]
        },
        // {
        //   'Russia': [
        //     { icon: 'mdi-twitter', url: 'https://twitter.com/Whatsfy_RU' },
        //     { icon: 'mdi-send', url: 'https://t.me/whatsfy_ru' },
        //   ]
        // },
      ]
    },
    items () {
      if (this.isLogin) {
        // * если юзер залогинен
        return [
          { title: this.$t('5Vh_KF2xGiZtNgIvlhvMA'), icon: 'mdi-apps', url: '/' },
          { title: this.$i18n.t('oYmrtZAlgQIbg0QWKW5Fk'), icon: 'mdi-wallet', url: '/plans' },
          { title: this.$t('PwVnNust10MFtqUMn47nQ'), icon: 'mdi-lifebuoy', url: '/help' },
          { title: this.$t('2Srvz_dWJpIPVp4G43jA7'), icon: 'mdi-logout', url: '/logout' }
        ]
      }
      // * если юзер не залогинен
      return [
        { title: this.$t('5Vh_KF2xGiZtNgIvlhvMA'), icon: 'mdi-apps', url: '/' },
        { title: this.$t('oYmrtZAlgQIbg0QWKW5Fk'), icon: 'mdi-wallet', url: '/plans' },
        { title: this.$t('PwVnNust10MFtqUMn47nQ'), icon: 'mdi-lifebuoy', url: '/help' },
        { title: this.$t('FfZqqb6Xd-LAVE7spZ8_9'), icon: 'mdi-login', url: '/login' },
        { title: this.$t('Q5KDVLJMgWKEAWfrDIiYg'), icon: 'mdi-account-plus', url: '/reg' }
      ]
    }
  }
}
</script>

<style>
.sale-bnnr {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

a:visited .report-card * {
  color: grey;
  /* opacity: 0.1 !important; */
}

.v-toolbar__title {
  color: #8595ae;
}
footer a {
  color: white !important;
}
.pointer {
  cursor: pointer;
}
.demo-lock {
  filter: blur(5px);
  user-select: none;
  cursor: no-drop !important;
}
/* .demo-lock::before {
  content: "🔒";
  filter: blur(0) !important;
  background: inherit;
} */
.wordwrap {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

.footer-list {
  list-style-type: none;
}
</style>