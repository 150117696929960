import Vue from 'vue'
import Router from 'vue-router'
import i18n from '@/locale'
import store from '@/store'

const HomeView = () => import('@/views/HomeView')
const BalanceView = () => import('@/views/BalanceView')
const HelpView = () => import('@/views/HelpView')

const LoginView = () => import('@/views/auth/LoginView')
const RegView = () => import('@/views/auth/RegView')

const ReportView = () => import('@/views/ReportView')

Vue.use(Router)

const router = new Router({
    base: '/app/',
    scrollBehavior () {
        return { x: 0, y: 0 }
    },
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'home',
            component: HomeView,
            meta: {
                title: i18n.t('qp6QBbS7sNDo1XKl1s2OB')
            }
        },

        {
            path: '/report/:reportId',
            name: 'report',
            component: ReportView,
            meta: {
                title: i18n.t('qp6QBbS7sNDo1XKl1s2OB')
            }
        },

        {
            path: '/login',
            name: 'login',
            component: LoginView,
            meta: {
                title: i18n.t('FfZqqb6Xd-LAVE7spZ8_9')
            }
        },

        {
            path: '/logout',
            name: 'logout',
            beforeEnter: (to, from, next) => {
                store.dispatch('setMessage', {
                    type: 'success',
                    text: i18n.t('bFYA6xEfwFeegXg1yCBmb')
                })
                store.dispatch('logoutUser')
                next({ name: 'login' })
            }
        },

        {
            path: '/reg',
            name: 'reg',
            component: RegView,
            meta: {
                title: i18n.t('Q5KDVLJMgWKEAWfrDIiYg')
            }
        },

        {
            path: '/plans',
            alias: '/tariffs',
            name: 'balance',
            component: BalanceView,
            meta: {
                title: i18n.t('wviCsXXIszy1_35PFHuUW')
            }
        },

        {
            path: '/help',
            name: 'help',
            component: HelpView,
            meta: {
                title: i18n.t('nAg-NGt02vNuZ1sIrHved')
            }
        },

        { path: '*', redirect: '/' }
    ]
})

/** Защита роутеров */
router.beforeEach((to, from, next) => {
    Vue.nextTick(() => {
        document.title = to.meta.title + ' ▪️ Whatsfy' || 'Whatsfy'
    })

    const currentUser = store.dispatch('getUserId')
    if (to.meta.auth && !currentUser) {
        store.dispatch('setMessage', {
            type: 'error',
            text: i18n.t('xRPVZrtH5PgZNsNnYa2ec')
        })
        next({ name: 'login' })
    } else {
        next()
    }
})

export default router
