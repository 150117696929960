import Vue from 'vue'
import VueI18n from 'vue-i18n'
import ru from './lang/ru.json'
import en from './lang/en.json'

// https://qna.habr.com/q/610851
// https://kazupon.github.io/vue-i18n/started.html#javascript

Vue.use(VueI18n)

const messages = {
    ru,
    en
}

const numberFormats = {
    en: {
        currency: {
            style: 'currency',
            currency: 'USD'
        }
    },
    ru: {
        currency: {
            style: 'currency',
            currency: 'RUB',
            currencyDisplay: 'symbol'
        }
    }
}

const dateTimeFormats = {
    en: {
        short: {
            month: 'numeric',
            day: 'numeric'
        },
        long: {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
        },
        datetime: {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
        }
    },
    ru: {
        short: {
            month: 'numeric',
            day: 'numeric'
        },
        long: {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
        },
        datetime: {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
        }
    }
}

export default new VueI18n({
    dateTimeFormats,
    numberFormats,

    locale:
        navigator.language.slice(0, 2) ||
        navigator.systemLanguage.slice(0, 2) ||
        navigator.userLanguage.slice(0, 2) ||
        'en',

    fallbackLocale: 'en',
    messages
})
