import Vue from 'vue'
import VueGtm from '@gtm-support/vue2-gtm'
import moment from 'moment'
import VueMoment from 'vue-moment'
import App from './App.vue'
import store from './store'
import router from './router'
import i18n from './locale'
import vuetify from './plugins/vuetify'

import config from './config'
import './registerServiceWorker'

import Ads from 'vue-google-adsense'

Vue.config.productionTip = false

Vue.use(require('vue-script2'))
Vue.use(Ads.Adsense)
Vue.use(Ads.AutoAdsense, { adClient: 'ca-pub-5616127056097007' })

Vue.use(VueGtm, config.gtm)

Vue.use(VueMoment, { moment })

let app

if (!app) {
    app = new Vue({
        i18n,
        vuetify,
        router,
        store,
        data: config.data,
        async created () {
            try {
                const response = await fetch('https://ip2c.org/s')
                    .then(response => response.text())
                    .then(response => {
                        const result = (response || '').toString()
                        if (!result || result[0] !== '1') {
                            throw new Error('unable to fetch the country')
                        }
                        return (
                            result.substr(2, 2) ||
                            navigator.language.slice(0, 2) ||
                            navigator.systemLanguage.slice(0, 2) ||
                            navigator.userLanguage.slice(0, 2) ||
                            'US'
                        )
                    })
                this.country = response.toUpperCase()
            } catch (e) {
                this.country = 'US'
            }
            this.lang =
                navigator.language.slice(0, 2) ||
                navigator.systemLanguage.slice(0, 2) ||
                navigator.userLanguage.slice(0, 2) ||
                'en'
        },
        publicPath: '/app/',
        render: h => h(App)
    }).$mount('#app')

    app.$store.dispatch('autoLogin')

    // автоматическая смена языка в зависимости от браузера
    app.$store.dispatch('setLang', app.$root.lang)

    app.$vuetify.theme.dark =
        window.matchMedia &&
        window.matchMedia('(prefers-color-scheme: dark)').matches
            ? true
            : false

    moment.locale(i18n.locale)
}
