import config from '@/config'
import { getCookie } from '@/plugins/cookies'

export default {
    state: {
        report: [],
        reportList: [],
        randomReportList: [],
        reportFilter: {}
    },

    getters: {
        report (state) {
            return state.report
        },
        reportList (state) {
            return state.reportList
        },
        randomReportList (state) {
            return state.randomReportList
        },
        reportFilter (state) {
            return state.reportFilter
        }
    },

    mutations: {
        SET_REPORT_FILTER (state, payload) {
            state.reportFilter = Object.assign(state.reportFilter, payload)
        },
        LOAD_RANDOM_REPORT_LIST (state, payload) {
            state.randomReportList = payload
        },
        CLEAR_RANDOM_REPORT_LIST (state) {
            state.randomReportList = []
        },
        LOAD_REPORT_LIST (state, payload) {
            state.reportList = payload
        },
        CLEAR_REPORT_LIST (state) {
            state.reportList = []
        },
        LOAD_REPORT (state, payload) {
            state.report = payload
        },
        CLEAR_REPORT (state) {
            state.report = []
        }
    },

    actions: {
        setReportFilter ({ commit }, payload) {
            commit('SET_REPORT_FILTER', payload)
        },
        async getRandomReportList (
            { state, dispatch, commit },
            { month, country, reportId }
        ) {
            /**
             * Создает список из случайных отчетов

             reportId -  айди отчета для исключения
             */
            commit('CLEAR_RANDOM_REPORT_LIST')

            const reportList = await dispatch('getReportList')

            if (!state.reportList) {
                commit('LOAD_RANDOM_REPORT_LIST', { month, country })
                return
            }

            let n = 4 // кол-во возвращаемых отчетов

            let result = new Array(n),
                len = reportList.length,
                taken = new Array(len)
            if (n > len) return []
            while (n--) {
                var x = Math.floor(Math.random() * len)
                if (reportList[x].report_id === reportId) {
                    n++
                    continue
                }
                result[n] = reportList[x in taken ? taken[x] : x]
                taken[x] = --len in taken ? taken[len] : len
            }

            commit('LOAD_RANDOM_REPORT_LIST', result)
        },

        async getReport ({ state, commit, dispatch }, { reportId }) {
            /** Загрузка отчета из БД
             */
            commit('CLEAR_MESSAGE')
            commit('CLEAR_REPORT')
            commit('SET_LOADING', true)

            const auth = state.token || getCookie('authtoken')

            try {
                // кеш-айди если только есть логин
                const cacheId = (await dispatch('getUserId'))
                    ? await dispatch('getCacheId')
                    : ''

                const response = await fetch(
                    `${config.data.apiEndpoint}/report/full/${reportId}/${cacheId}`,
                    {
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer: ${auth}`,
                            'Content-Type': 'application/json;charset=utf-8'
                        }
                    }
                )
                const report = await response.json()
                //FIXME добавить проверку на ошибку в ответе

                commit('CLEAR_MESSAGE')
                commit('SET_LOADING', false)

                commit('LOAD_REPORT', report)
            } catch (error) {
                commit('SET_LOADING', false)
                commit('SET_MESSAGE', { type: 'error', text: error.message })
                throw error
            }
        },

        async getReportList ({ state, commit, dispatch }) {
            /** Загрузка списка отчетов
             */

            commit('SET_LOADING', true)
            commit('CLEAR_MESSAGE')
            commit('CLEAR_REPORT_LIST')

            const auth = state.token || getCookie('authtoken')

            try {
                // кеш-айди если только есть логин
                const cacheId = (await dispatch('getUserId'))
                    ? await dispatch('getCacheId')
                    : ''

                const response = await fetch(
                    `${config.data.apiEndpoint}/report/list/${cacheId}`,
                    {
                        method: 'POST',
                        body: JSON.stringify(state.reportFilter),
                        headers: {
                            Authorization: `Bearer: ${auth}`,
                            'Content-Type': 'application/json;charset=utf-8'
                        }
                    }
                )
                const reports = await response.json()

                commit('CLEAR_MESSAGE')
                commit('LOAD_REPORT_LIST', reports)
                commit('SET_LOADING', false)

                return reports
            } catch (error) {
                commit('SET_MESSAGE', { type: 'error', text: error.message })
                throw error
            }
        }
    }
}
