<template>
  <v-snackbar
    v-if="message.text"
    class="mb-5"
    :color="message.type"
    :multi-line="true"
    :timeout="5000"
    @input="closeMessage"
    :value="true"
  >
    <template v-if="message.to">
      <router-link
        :to="message.to"
        class="white--text"
      >
        {{ message.text }}
      </router-link>
    </template>
    <template v-else>
      {{ message.text }}
    </template>

    <template v-slot:action="{ attrs }">
      <v-btn
        v-bind="attrs"
        text
        @click="closeMessage"
      >
        <v-icon>mdi-close-circle</v-icon>
      </v-btn>
    </template>

  </v-snackbar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {

  computed: {
    ...mapGetters(['message'])
  },

  methods: {
    ...mapActions(['clearMessage']),

    closeMessage () {
      this.clearMessage()
    }
  }
}
</script>
