<template>
  <span
    :title="title || url"
    @click.prevent="click"
    :class="{ 'disabled-link':disabled }"
    class="js-url"
  >
    <slot></slot>
  </span>
  <!-- <a :href="resultUrl" target="_blank" @click="click" rel="nofollow noreferrer noopener">{{ text }}</a> -->
</template>

<script>
export default {
  name: 'JsUrl',
  props: ['url', 'title', 'disabled'],

  // data () {
  //   return {
  //     redirectUrl: '<html><meta name="robots" content="noindex, nofollow, noarchive" /><meta name="referrer" content="no-referrer"/><meta http-equiv="cache-control" content="no-cache"/><meta http-equiv="expires" content="0"/><meta http-equiv="pragma" content="no-cache"/><meta http-equiv="Refresh" content="0; URL=' + this.url + '" /><head></head><body><s' + 'cript>setTimeout(function(){window.location="' + this.url + '"}, 2000);</sc' + 'ript></body></html>'
  //   }
  // },

  methods: {
    click: function (e) {
      e.preventDefault()
      // const head = `<meta name='robots' content='noindex, nofollow, noarchive' /><meta name='referrer' content='no-referrer'/><meta http-equiv='cache-control' content='no-cache'/><meta http-equiv='expires' content='0'/><meta http-equiv='pragma' content='no-cache'/><meta http-equiv='Refresh' content='0; URL=${this.url}' />`

      // const body = '<html>' + head + '<head></head><body><s' + 'cript>setTimeout(function(){window.location="' + this.url + '"}, 2000);</sc' + 'ript></body></html>'

      // const resultUrl = 'data:text/html;charset=utf-8,' + encodeURIComponent(body)
      // console.log(resultUrl)
      // window.open(this.resultUrl, '_blank')
      // window.open().document.body.innerHTML = body
      if (!this.disabled) {
        window.open(this.url, '_blank')
      }
      return false
    }
  }
}
</script>

<style scoped>
.js-url {
  cursor: pointer;
}
.disabled-link {
  /* opacity: 0.5; */
  user-select: none;
  cursor: no-drop !important;
}
</style>
