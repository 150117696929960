import i18n from '@/locale'

export default {
    state: {
        loading: false,
        message: {
            type: '',
            text: '',
            to: ''
        }
    },

    mutations: {
        SET_LOADING (state, payload) {
            state.loading = payload
        },
        SET_MESSAGE (state, payload) {
            state.message = payload
        },
        CLEAR_MESSAGE (state) {
            state.message = {
                type: '',
                text: ''
            }
        },
        SET_LANG (state, payload) {
            i18n.locale = payload
        }
    },

    actions: {
        setLoading ({ commit }, payload) {
            commit('SET_LOADING', payload)
        },
        setMessage ({ commit }, payload) {
            commit('SET_MESSAGE', payload)
        },
        clearMessage ({ commit }) {
            commit('CLEAR_MESSAGE')
        },

        async setLang ({ commit }, payload) {
            if (payload in i18n.messages) {
                commit('SET_LANG', payload)
            } else {
                try {
                    const res = await import(`@/locale/lang/${payload}.json`)
                    await i18n.setLocaleMessage(payload, res)
                    commit('SET_LANG', payload)
                } catch (e) {
                    console.log(e)
                }
            }
        }
    },

    getters: {
        loading (state) {
            return state.loading
        },
        message (state) {
            return state.message
        }
    }
}
